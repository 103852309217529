import React from 'react';
import styled from '@emotion/styled';

import { IconChevronDown, IconChevronUp } from '~/UI/Icons/Chevron';
import Button from '~/UI/ButtonAnt';
import TooltipWithEllipsis from '~/UI/TooltipWithEllipsis';
import { colors } from '~/utils/theme';
import { generateTestId, TestIdProps } from '~/utils/dataTestProps';
import IntroWrapper from '~/components/IntroWrapper';
import type { IntroWrapperProps } from '~/components/IntroWrapper';
import Space from '~/UI/Space';
import { theme } from '~/utils';
import ModuleTag from '~/components/ModuleTag';
import { usaFlagIcon } from '~/assets';
import ModulePopover from './ModulePopover';

export type Item = {
  label: string;
  value: string;
  key?: string;
  introWrapperProps?: IntroWrapperProps;
  success?: boolean;
  color?: string;
  backgroundColor?: string;
  domesticContent?: number;
};

export interface CollapsibleHeroModuleProps {
  items: Array<Item>;
  additionalItems?: Array<Item>;
  showToggle?: boolean;
  defaultExpanded?: boolean;
  customToggle?: React.ReactNode;
  className?: string;
  testIdData?: TestIdProps;
}

const StyledButton = styled(Button)<{ showAdditionalItems?: boolean }>`
  background: ${({ showAdditionalItems }) =>
    showAdditionalItems ? colors.gray200 : 'transparent'};
  border-color: ${colors.gray200};

  &:focus,
  &:hover {
    border-color: ${colors.gray200};
    background: ${({ showAdditionalItems }) =>
      showAdditionalItems ? colors.gray200 : 'transparent'};
  }
  margin-top: 8px;
`;

const Grid = styled.div<{ showToggle: boolean; itemsLength: number }>`
  display: grid;
  grid-template-columns: ${({ showToggle, itemsLength }) =>
    showToggle
      ? `${'1fr '.repeat(itemsLength)} 32px`
      : `repeat(${itemsLength}, 1fr)`};
  align-items: flex-start;
  transition: all 220ms ease-in-out;
  row-gap: 20px;
  justify-content: space-between;
`;

const StyledIntroWrapper = styled(IntroWrapper)`
  width: fit-content;
  position: relative;
`;

const Label = styled.p<{ color?: string; backgroundColor?: string }>`
  font-size: 12px;
  line-height: initial;
  font-weight: 500;
  color: ${({ color }) => color || colors.gray600};
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : ''}
`;

const Value = styled.h3<{ $success?: boolean }>`
  font-size: 16px;
  line-height: initial;
  font-weight: 700;
  color: ${(props) => (props.$success ? theme.colors.success : colors.gray800)};
  max-width: 300px;
`;

const StyledSpace = styled(Space)`
  padding: 8px 0;
  padding-bottom: 4px;
`;

const CustomModuleTag = styled(ModuleTag)`
  position: absolute;
  width: fit-content;
  background-color: transparent;
  padding: 0;
  div {
    justify-content: flex-start;
  }
`;

const CollapsibleHeroModule: React.FC<CollapsibleHeroModuleProps> = ({
  items,
  additionalItems,
  showToggle = true,
  defaultExpanded = false,
  className,
  testIdData,
}) => {
  const [showAdditionalItems, setShowAdditionalItems] =
    React.useState(defaultExpanded);

  return (
    <Grid
      showToggle={showToggle}
      itemsLength={items.length}
      className={className}
    >
      {items.map(
        ({
          label,
          value,
          key = '',
          introWrapperProps,
          color,
          backgroundColor,
          success,
          domesticContent,
        }) => (
          <StyledIntroWrapper key={label} {...introWrapperProps}>
            <StyledSpace direction="vertical" size="small">
              <Label
                color={color}
                backgroundColor={backgroundColor}
                data-testid={generateTestId({
                  ...testIdData,
                  identifier: key,
                } as TestIdProps)}
              >
                {label}
              </Label>
              <TooltipWithEllipsis
                id={label}
                text={value}
                component={
                  <Value
                    data-testid={generateTestId({
                      ...testIdData,
                      identifier: `${key}-value`,
                    } as TestIdProps)}
                    $success={success}
                  >
                    {value}
                  </Value>
                }
              />
            </StyledSpace>
            {key === 'effective-price' && domesticContent !== 0 && (
              <ModulePopover
                title="Domestic Content Tax Credit"
                content="The value of the Domestic Content Tax Credit in $/W"
                getPopupContainer={(triggerNode) => triggerNode}
              >
                <CustomModuleTag
                  size="sm"
                  icon={<img src={usaFlagIcon} alt="USA flag" />}
                  label={`$${domesticContent?.toFixed(3)}`}
                />
              </ModulePopover>
            )}
          </StyledIntroWrapper>
        )
      )}
      {showToggle ? (
        <StyledButton
          showAdditionalItems={showAdditionalItems}
          onClick={() => setShowAdditionalItems(!showAdditionalItems)}
          icon={
            showAdditionalItems ? (
              <IconChevronUp color={colors.gray800} />
            ) : (
              <IconChevronDown color={colors.gray800} />
            )
          }
          size="middle"
        />
      ) : null}
      {additionalItems && showAdditionalItems ? (
        <>
          {additionalItems.map((item) => (
            <StyledSpace direction="vertical" size="small" key={item.label}>
              <Label>{item.label}</Label>

              <TooltipWithEllipsis
                id={item.label}
                text={item.value}
                component={<Value>{item.value}</Value>}
              />
            </StyledSpace>
          ))}
        </>
      ) : null}
    </Grid>
  );
};

export default CollapsibleHeroModule;
